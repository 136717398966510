import Logo from '../assets/Logo.png'
// import UserICO from '../assets/user-ico.png'
import RegisterIcon from '../assets/register.png'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Menu from './Menu';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import '../pages/register.css';
// import API from '../api';
import GIFLOADER from '../assets/loader.gif';
import NewUserIcon from '../assets/question.png';
import { Link } from 'react-router-dom';
// import { AES, enc } from 'crypto-js';
import Footer from './Footer';
import register_api from '../api/register_api.js';
import MobileMenu from './MobileMenu.js';
import pdf from "../assets/maxontutorial.pdf";


const Register = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const LoginStyle = {
        color: '#FFF100',
        textDecoration: 'none',
    }


    var [suchidden, ShowSuc] = useState(true);
    var [errhidden, ShowErr] = useState(true);

    var [suMSG, SetSuMsg] = useState('');
    var [erMSG, SetErMsg] = useState('');

    var [btndis, SetBtnDisable] = useState(true);

    const handleKeyDown = event => {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
        // if (!/[0-9a-zA-Z]/.test(event.key)) {
        //     event.preventDefault();
        // }
    };
    function spaceBlock() {
        var space = document.getElementById('company_name');
        console.log(space.selectionStart)

        if (space.selectionStart === 0 && window.event.code === "Space") {
            window.event.preventDefault();
        }
    }
    const handleKeyDownName = event => {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
        if (!/[a-zA-Z]/.test(event.key)) {
            event.preventDefault();
        }
    };
    const handleKeyDownEmail = event => {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    }
    useEffect(() => {
        // window.history.forward();
    });
    function onResumeClick() {
        window.open(pdf);
    }
    const onSubmit = async (req_data, event) => {
        SetBtnDisable(btndis = false);
        console.log(btndis);
        var response_data = await register_api(req_data.maxon_account_no, req_data.first_name, req_data.last_name, req_data.email_id, req_data.company_name);
        console.log(response_data);

        if (response_data.success) {
            SetSuMsg(suMSG = response_data.msg);
            ShowSuc(!suchidden);
            setTimeout(() => {
                ShowSuc(suchidden = true);
            }, 3000);
            event.target.reset();
            SetBtnDisable(btndis = true);
        } else {
            SetErMsg(suMSG = response_data.msg);
            ShowErr(!errhidden);
            setTimeout(() => {
                ShowErr(errhidden = true);
            }, 3000);
            SetBtnDisable(btndis = true);
        }
    }
    return (
        <div className='container-bg pt-5'>
            <MobileMenu />
            <div className='container'>

                <div className='bg-grey-top'>
                    <div className='toaster suc' hidden={suchidden}>
                        <h2>{suMSG}</h2>
                    </div>

                    <div className='header d-flex justify-content-between d-none d-md-flex'>
                        <img className='logo' src={Logo} alt="Maxon logo" />
                        <Menu />
                    </div>
                    <div className='bg-black-bottom row text-center pt-md-5'>
                        {/* <div className='bg-black-bottom col-md-5 d-flex flex-column justify-content-center align-items-center'>
                            <h1>Create an Account</h1>
                            <p>Register now to join the dealership program</p>
                            <img className='max-maxlogo d-none d-md-flex' src={Logo} alt="Maxon logo" />
                        </div> */}
                        <div className='bg-black-bottom col-md-5 mx-auto'>
                            <form className='form-area p-5' onSubmit={handleSubmit(onSubmit)}>
                                <div hidden={btndis} className='loader'>
                                    <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                                </div>
                                <h5>Register with credentials</h5>
                                <div className='p-1'>
                                    <input placeholder='Maxon Account Number' onKeyDown={handleKeyDown} className='form-control' id="maxon_account_no" maxLength={20}
                                        {...register("maxon_account_no", {
                                            required: true,
                                        })} />
                                    {errors.maxon_account_no?.type === "required" && (
                                        <small className='errMsg'>Please enter the Maxon Account Number</small>
                                    )}
                                </div>
                                <div className='p-1 row'>
                                    <div className='col-md-6'>
                                        <input placeholder='First Name' className='form-control'
                                            id="first_name" onKeyDown={handleKeyDownName} maxLength={20}
                                            {...register("first_name", {
                                                required: true,
                                            })}

                                        />
                                        {errors.first_name?.type === "required" && (
                                            <small className='errMsg'>Please provide your First Name</small>
                                        )}
                                    </div>
                                    <div className='col-md-6'>
                                        <input placeholder='Last Name' className='form-control'
                                            id="last_name" onKeyDown={handleKeyDownName} maxLength={20}
                                            {...register("last_name", {
                                                required: true,
                                            })} />
                                        {errors.last_name?.type === "required" && (
                                            <small className='errMsg'>Please enter your Last Name</small>
                                        )}
                                    </div>
                                </div>
                                <div className='p-1 emailfield'>
                                    <input placeholder='Email' className='form-control'
                                        id='email_id' onKeyDown={handleKeyDownEmail}
                                        {...register("email_id", {
                                            required: "Please enter your Email",
                                            validate: {
                                                maxLength: (v) =>
                                                    v.length <= 50 || "The email should have at most 50 characters",
                                                matchPattern: (v) =>
                                                    /^[a-zA-Z0-9]+([._-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,3}$/.test(v) ||
                                                    "Email address must be a valid",
                                            },
                                        })}
                                    />
                                    {errors.email_id?.message && (
                                        <small className='errMsg'>{errors.email_id.message}</small>
                                    )}
                                </div>
                                <div className='p-1'>
                                    <input placeholder='Company Name' maxLength={50} className='form-control' id="company_name" onKeyDown={spaceBlock}
                                        {...register("company_name", {
                                            required: true,
                                        })} />
                                    {errors.company_name?.type === "required" && (
                                        <small className='errMsg'>Please  provide the Company Name</small>
                                    )}<br />                                    
                                    <div className='toster_Msg fail' hidden={errhidden}>
                                        <span>{erMSG}</span>
                                    </div>
                                </div>

                                <div className='p-1'>
                                    <button className='btn register-btn'>
                                        <img src={RegisterIcon} alt="register-btn" />
                                        Register
                                    </button>
                                </div>
                                <div id='mob-user-tutor' className='pt-4 d-sm-block d-md-none' onClick={onResumeClick} >
                                    <img src={NewUserIcon} alt="New User Icon" />
                                    <a target="_blank"><span>USER TUTORIAL</span></a>
                                </div>
                            </form>

                            <p className='already-acc text-center mt-4'>Already have an account? <Link to="/" style={LoginStyle}>Login</Link></p>
                        </div>
                    </div>
                </div>

                <div className='register_footer'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Register;