import React, { useEffect, useState } from "react";
import LEFTMENU from "./leftMenu";
import "../pages/upload.css";
import upload_pic from '../assets/upload.png';
import upload_icon from '../assets/uploadiconsvg.svg';
import * as XLSX from 'xlsx';
import GIFLOADER from '../assets/loader.gif';
import downloadsam from '../assets/downsampsvg.svg';
import { useNavigate } from 'react-router-dom';
import { bulk_claim_upload_api } from '../api/bulk_claim_upload_api.js';
import pdf from "../assets/maxontutorial.pdf";
import { Link } from 'react-router-dom';
import Topmenu from "./topmenu.js";
import newlogout from '../assets/logoutblack.svg';


function Upload() {
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);



    const validColumnName = ["InvoiceNo", "SerialNo", "RepairDate", "InvoiceDate", "Meter", "RepLine", "RepCode", "ClmHrs", "ClmRate", "MiscClm", "MiscDesc", "Notes", "PartNo", "Qty", "Price"];

    const [filename, setFilename] = useState();
    const [jsonData, setJsonData] = useState(null);

    var [suchidden, ShowSuc] = useState(true);
    var [errhidden, ShowErr] = useState(false);
    var [suMSG, SetSuMsg] = useState('');
    var [erMSG, SetErMsg] = useState('');
    var [btndis, SetBtnDisable] = useState(true);
    const navigate = useNavigate();

    var curdate = localStorage.getItem('currentDate');
    function uploadfile(event) {
        event.preventDefault();

        if (event.target.files[0]) {
            // console.log(event.target.files[0].name)
            setFilename(event.target.files[0].name)

            const selectedFile = event.target.files[0];
            const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

            if (fileExtension === 'xls' || fileExtension === 'xlsx' || fileExtension === 'csv') {
                setFile(selectedFile);
                //   validateFile(selectedFile);
            } else {
                setError('Please upload a valid .xls, .xlsx, or .csv file.');
                setFile(null);
                setFilename('');
                setJsonData(null);
                SetErMsg('Please upload a valid .xls, .xlsx, or .csv file.');
                ShowErr(errhidden = true);
                setTimeout(() => {
                    ShowErr(errhidden = false);
                }, 5000);
                SetBtnDisable(btndis = true);
                return
            }

            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const firstColumn = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    const headers = firstColumn[0];

                    const isValid = validColumnName.every(name => headers.includes(name));
                    if (!isValid) {
                        //   setError(`Invalid columns. Expected columns are: ${validColumnName.join(', ')}`);
                        setFile(null);
                        setFilename('');
                        setJsonData(null);
                        SetErMsg('Invalid columns.');
                        ShowErr(errhidden = true);
                        setTimeout(() => {
                            ShowErr(errhidden = false);
                        }, 5000);
                        SetBtnDisable(btndis = true);
                        return
                    } else {
                        setError(null);
                        const json = XLSX.utils.sheet_to_json(worksheet);
                        setJsonData(json);
                    }
                    console.log(headers);

                };
                reader.readAsArrayBuffer(file);
            }
        }
    }
    function onResumeClick() {
        window.open(pdf);
    }
    const handleFileUpload = async () => {
        console.log("----------------------upload")
        SetBtnDisable(btndis = false);
        if (jsonData == null) {
            SetErMsg('Please select a valid file.');
            ShowErr(errhidden = true);
            setTimeout(() => {
                ShowErr(errhidden = false);
                SetBtnDisable(btndis = true);
            }, 2000);

        } else if (jsonData.length == 0) {
            setFile(null);
            setFilename('');
            setJsonData(null);
            SetErMsg('No Data Found');
            ShowErr(errhidden = true);
            setTimeout(() => {
                ShowErr(errhidden = false);
                SetBtnDisable(btndis = true);
            }, 2000);
        } else {
            var req_data = { "record": jsonData, "file_name": filename, "upload_file": file };

            var response_data = await bulk_claim_upload_api(req_data);

            if (response_data.success) {
                setFile(null);
                setFilename('');
                setJsonData(null);
                ShowSuc(suchidden = false);
                SetSuMsg(response_data.msg);
                setTimeout(() => {
                    SetBtnDisable(btndis = true);
                    ShowSuc(suchidden = true);
                    // return navigate("/claims");
                }, 2000);
            } else {
                setFile(null);
                setFilename('');
                setJsonData(null);
                SetErMsg(suMSG = response_data.msg);
                ShowErr(errhidden = true);
                setTimeout(() => {
                    ShowErr(errhidden = false);
                    SetBtnDisable(btndis = true);
                }, 5000);
            }
        }
    };

    return (
        <div className="sideMenu">
             <LEFTMENU />
            {/* <div className="menuall">
                <div className="lmenu">
                    <LEFTMENU />
                </div>
                <div className="tmenu">
                    <Topmenu />
                </div>
            </div> */}
            <div className="container details_All">
                <div hidden={btndis} className='loader'>
                    <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                </div>
                <div className='toaster suc' hidden={suchidden}>
                    <h2>{suMSG}</h2>
                </div>
                {errhidden && <div className='toaster fail mdesc' >
                    <h2>{erMSG}</h2>
                </div>}
                <div className="claim_top_bar">

                    <div className="user_topbar">
                        <h3> Claim Upload</h3>
                        <div className="warrtylog" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            <div className="logImg"><img src={newlogout}></img></div>
                            <div className="warrntyp" >
                                <p>LOG OUT</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="upload_card">
                    <div className="card">
                        <div className="card-body">
                            <div className="upload_title">
                                <p>File Upload</p>
                            </div>
                            {/* <hr></hr> */}
                            <div className="upload_file">
                                <img src={upload_pic} />
                                <p>Click or drag file to this area to upload</p>
                                <p className="file_name">{filename}</p>
                                <input type="file" accept=".xlsx, .csv" onChange={uploadfile} />
                            </div>
                            <div className="format_acct">
                                <p>Formats Accepted are .csv and .xlsx</p>
                            </div>
                            {/* <hr></hr> */}
                            <div className="upload_sample">
                                <p>Download sample template below:</p>
                                <div className="down_samp">
                                    <img src={downloadsam} />
                                    <a href="/SampleFile.xlsx" download="SampleFile.xlsx">Download sample template </a>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="upload_btns">
                                <div className="uplo_btn" >
                                    <a className="upl_btn" onClick={handleFileUpload}> <img className="upl_arrow" src={upload_icon} />Upload</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="home_footer claim_footer">
                    <div className="footer_copy">
                        <p>Copyright &copy; {curdate} Maxon Lift Crop. All rights reserved</p>
                    </div>
                    <div className="home_content">
                        <div className="clmuser" onClick={onResumeClick} >
                            <a target="_blank"><span>User Tutorial</span></a>
                        </div>
                        <Link to={'/privacy-policy'}>
                            Policy Privacy</Link>
                        <Link to={'/terms-Conditions'}>
                            Terms & Conditions</Link>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Upload;