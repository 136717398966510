import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import '../pages/Addclaim.css';
import LEFTMENU from "./leftMenu";
import DraftBTNIcon from '../assets/save-draft-icon.png';
import SubmitBTNIcon from '../assets/sub-btn-icon.png';
import CanCelBtn from '../assets/cancel-claim-btn.png';
import { add_claim_api } from '../api/add_claim_api.js';
import * as Yup from 'yup';
import GIFLOADER from '../assets/loader.gif';
import {
    category_list_api, parts_list_search_api,
    gate_type_list_api, component_list_api, validate_serial_api, hours_list_api, parts_listing_api, get_web_reference_no_api, get_part_description_api, get_part_number_api
} from "../api/meta_data_api.js";
import * as moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Topmenu from './topmenu.js';
import axios from 'axios';
import backnew from "../assets/backnew.svg"
import pdf from "../assets/maxontutorial.pdf";
import { Link } from "react-router-dom";
import newlogout from '../assets/logoutblack.svg';

const schema = Yup.object().shape({

    web_reference_number: Yup.string()
        .required('Web reference number is required'),
    web_entry_date: Yup.date().default(function () {
        return new Date();
    }),
    serial_no: Yup.string()
        .required('Serial number is required'),
    repair_date: Yup.string()
        .required('Date of Birth is required'),
    // .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
    invoice_no: Yup.string()
        .required('Invoice number is required'),
    cycle_count: Yup.number().required('Cycle Count is required'),
    invoice_date: Yup.string()
        .required('Invoice date is required'),
    model_no: Yup.string(),
    company_name: Yup.string()
        .required('Company name is required'),
    purchase_date: Yup.string()
        .required('Purchase date is required'),
    repair_list: Yup.array().of(
        Yup.object().shape({
            category: Yup.string().required("Category is required"),
            gate_type: Yup.string().required("Gatetype is required"),
            component: Yup.string().required("Component is required"),
            labour_hours: Yup.number()
                .required("Labor Hours is required")
                .positive('Positive numbers only allowed'),
            labour_rate: Yup.number()
                .required("Labor rate is required")
                .positive('Positive numbers only allowed'),
            labour_total: Yup.number().required("Labor total is required").positive('Positive numbers only allowed'),
            misc_charge: Yup.number().positive().integer().required(),
            // misc_description: Yup.string().required("Misc Description is required"),
            // repair_Description: Yup.string().required("Repair Desc is required"),
            repair_parts_total: Yup.number().required("Repair Parts Total is required"),
        })
    ),
    known_option: Yup.string(),
    parts_list: Yup.array().of(
        Yup.object().shape({
            repair_no: Yup.string(),
            part_number: Yup.string(),
            // .required("Part Number is required"),
            parts_description: Yup.string(),
            // .required("Part Description is required"),
            quantity_claimed: Yup.string(),
            // .required("Quantity Claim is required"),
            unit_price_claimed: Yup.string(),
            // .required("Unit Price Claim is required"),
            partstotal: Yup.string()
            // .required("Total is required"),
        })
    )
});

function ClaimForm() {
    const { register, handleSubmit, control, unregister, getValues, setError, clearErrors, reset, watch, setValue, resetField, setFocus, formState: { errors } } = useForm({
        mode: "onBlur",
        defaultValues: {
            loadState: "unloaded",
            known_option: "Part No"
        }
    });




    const { fields: repair_list, append: appendRepair, remove: removeRepair, insert: insertRepair } = useFieldArray({
        control,
        name: 'repairs',
    });
    const { fields: parts_list, append: appendPart, remove: removePart, insert: insertPart } = useFieldArray({
        control,
        name: 'parts',
    });
    const [activeRepairIndex, setActiveRepairIndex] = useState(0);
    const [activePartIndex, setActivePartIndex] = useState(0);
    let [repairs, setRepairs] = useState([{
        category: '', gate_type: '', component: '', labour_hours: '', labour_rate: '', labour_total: '', misc_charge: '', misc_description: '', repair_Description: '', repair_parts_total: '', parts: [{
            repairpart: '0-0',
            repair_no: '',
            part_number: '',
            parts_description: '',
            quantity_claimed: '',
            unit_price_claimed: '',
            partstotal: ''
        }]
    }]);
    let [curRepairPartList, setcurRepairPartList] = useState([{
        repairpart: '0-0',
        repair_no: '',
        part_number: '',
        parts_description: '',
        quantity_claimed: '',
        unit_price_claimed: '',
        partstotal: ''
    }]);
    const navigate = useNavigate();

    // const dateValue = watch('repair_date');

    const SerialNo_Valid = watch('serial_no');

    var repair_category_id = "repairs." + activeRepairIndex + ".category";

    const current_repaircat_id = watch(repair_category_id);

    var repair_gate_id = "repairs." + activeRepairIndex + ".gate_type";

    const current_repairgate_id = watch(repair_gate_id);

    var repair_component_id = "repairs." + activeRepairIndex + ".component";

    const current_repaircompo_id = watch(repair_component_id);

    var repair_rate = "repairs." + activeRepairIndex + ".labour_rate";

    const current_repairrate_val = watch(repair_rate);

    var repair_laborHour = "repairs." + activeRepairIndex + ".labour_hours";

    const current_repairhour_value = watch(repair_laborHour);

    var repair_laborTot = "repairs." + activeRepairIndex + ".labour_total";

    var repairParts_Tot = "repairs." + activeRepairIndex + ".repair_parts_total";



    var repair_MiscCharge = "repairs." + activeRepairIndex + ".misc_charge";

    const current_repairmisc_char_val = watch(repair_MiscCharge);



    var Parts_No = "repairs." + activeRepairIndex + ".parts." + activePartIndex + ".part_number";
    // console.log(Parts_No);
    const current_part_no_val = watch(Parts_No);

    // var Parts_DESC = "repairs." + activeRepairIndex + ".parts."+activePartIndex+".part_description";
    // console.log(Parts_No);
    // const current_part_no_val = watch(Parts_DESC);

    var Parts_description_val = "repairs." + activeRepairIndex + ".parts." + activePartIndex + ".parts_description";
    // console.log(Parts_No);
    const current_partdesc_val = watch(Parts_description_val);


    var Quantity_claim_val = "repairs." + activeRepairIndex + ".parts." + activePartIndex + ".quantity_claimed";
    // console.log(Parts_No);
    const current_QuantityPrice_val = watch(Quantity_claim_val);

    var Unitprice_claim_val = "repairs." + activeRepairIndex + ".parts." + activePartIndex + ".unit_price_claimed";
    // console.log(Parts_No);
    const current_UnitPrice_val = watch(Unitprice_claim_val);

    var KnownOptionVal = "known_option";
    // console.log(Parts_No);
    const current_KnownOptionVal = watch(KnownOptionVal);

    var TotalQU_val = "repairs." + activeRepairIndex + ".parts." + activePartIndex + ".partstotal";

    const invofile_upload = watch('invoice_file');
    // console.log(Parts_No);
    // const current_TotalQU_val = watch(TotalQU_val); invoice_file
    var [suchiddenDraft, ShowSucDraft] = useState(true);
    var [suchidden, ShowSuc] = useState(true);
    var [errhidden, ShowErr] = useState(false);

    var [suWebMSG, SetsuWebMSG] = useState(false);
    var [suMSG, SetSuMsg] = useState('');
    var [erMSG, SetErMsg] = useState('');

    var [btndis, SetBtnDisable] = useState(true);
    const [isWarrentyExpired, setisWarrentyExpired] = useState(false);

    // const [isHourChangedMsg, setisHourChangedMsg] = useState(false);
    var [category_list, SetCategoryList] = useState([{}]);
    var [gate_type_list, SetGateTypeList] = useState([{}]);
    var [component, SetComponent] = useState([{}]);
    var [laborhours, SetLaborHours] = useState();
    var [repDate, SetrepDate] = useState(new Date());
    const [currentValue, setCurrentValue] = useState(undefined);
    var [partCount, SetPartCount] = useState(1);
    var [repairCount, SetRepairCount] = useState(1);
    var [totalCount, SetTotalCount] = useState(2);
    var [isSeValidMsg, setisSeiralValidMsg] = useState(false);
    var [serialNopartdesc, SetSerialNoPartDesc] = useState('');
    var [serialNocompanyname, SetSerialNoCompanyName] = useState('');
    var [serialNopurchaseDate, SetSerialpurchaseDate] = useState();
    var [NewWEbnoMSG, SetNewWEbnoMSG] = useState('');
    var [known_optionVal, Setknown_optionVal] = useState('Part No');

    var [repairGatetype, SetrepairGatetype] = useState('');
    var [repairComponent, SetrepairComponent] = useState('');
    var [repairTabChange, SetrepairTabChange] = useState(false);

    var [laborRate, SetlaborRate] = useState(0);

    var [laborCalcTotal, SetlaborCalcTotal] = useState(0);
    var [repairpartstotalCalc, SetrepairpartstotalCalc] = useState(0);

    var [WebRefNo, SetWebRefNo] = useState('');

    var [PART_DESC, SetPART_DESC] = useState();
    var [PART_NUMBER, SetPART_NUMBER] = useState('');
    var [QuantityClaim, SetQuantityClaim] = useState('');
    var [UnitPriceClaim, SetUnitPriceClaim] = useState('');

    var [QuantityunitTotalClaim, SetQuantityunitTotalClaim] = useState('');

    var [partLists, SetpartLists] = useState([{}]);

    var [IsMiscFieldValid, SetIsMiscFieldValid] = useState(false);

    var [IsAllFieldValid, SetIsAllFieldValid] = useState(true);
    const [show, setShow] = useState(false);
    var req_dataSaveDraft;
    var [file_invoice, SetFileInvoice] = useState(null);
    var [dateValid, setDateValid] = useState(false);
    var [invodate, setInvodate] = useState(false);
    var [crutdate, setCrutdate] = useState(false);
    var [recrutdate, setRecrutdate] = useState(false);
    var [fileerr, setFileErr] = useState(false);
    var [fileerrsize, setFileErrsize] = useState(false);

    const [activeyear, setActiveYear] = useState();


    const exceptThisSymbols = ["e"];

    const regx = "^\d+\.\d{0,2}$";

    // const FileOnchange = (event) => {
    //     if (event.target.files) {
    //         console.log(event.target.files[0])
    //         var fileExt = event.target.files[0]?.name.split('.').pop();
    //         console.log(fileExt)
    //         if (fileExt == 'pdf' || fileExt == 'png' || fileExt == 'jpg' || fileExt == 'jpeg') {
    //             console.log(event.target.files[0]);
    //             SetFileInvoice(event.target.files[0]);
    //             setFileErr(false)
    //         } else {
    //             setFileErr(true)
    //             setValue('invoice_file', '')
    //         }
    //     }
    //     else {
    //         console.log('file null')
    //         SetFileInvoice(null)
    //     }
    // }
    useEffect(() => {
        console.log(invofile_upload)
        // return
        if (invofile_upload != undefined && invofile_upload != '' && invofile_upload != null) {
            // console.log(event.target.files[0])
            var fileExt = invofile_upload[0]?.name.toLowerCase().split('.').pop();
            console.log(fileExt)
            if (fileExt == 'pdf' || fileExt == 'png' || fileExt == 'jpg' || fileExt == 'jpeg') {

                const maxFileSize = 15 * 1024 * 1024; // 15MB limit
                if (invofile_upload[0].size > maxFileSize) {
                    setFileErrsize(true)
                    setFileErr(false)
                    setValue('invoice_file', null)

                } else {
                    console.log(invofile_upload[0]);
                    SetFileInvoice(invofile_upload[0]);
                    setFileErr(false)
                    setFileErrsize(false)
                }

            } else {
            console.log('xlm')

                setFileErr(true)
                setFileErrsize(false)
                setValue('invoice_file', null)
            }
        } else {
            console.log('namefile')
            setFileErr(false)
            SetFileInvoice(null)
        }
    }, [invofile_upload])

    function onResumeClick() {
        window.open(pdf);
    }
    const handleSubmitDraft = async (event) => {
        event.preventDefault();
        SetIsAllFieldValid(false);
        req_dataSaveDraft = getValues();
        req_dataSaveDraft.invoice_file = file_invoice;
        req_dataSaveDraft.is_upload = true;
        req_dataSaveDraft.claim_expiry_date = seSerialNo;
        req_dataSaveDraft.partNum = serPartNum;
        const curDDate = moment(new Date()).format("MM-DD-YYYY");
        req_dataSaveDraft = getValues();
        req_dataSaveDraft.web_entry_date = curDDate;
        // return;
        console.log('req_data', req_dataSaveDraft);
        // return

        if (req_dataSaveDraft.serial_no === '' || isSeValidMsg === true) {
            // event.target.querySelector('#serialnothis').focus();
            setFocus('serial_no');
        } else {

            SetBtnDisable(btndis = false);

            req_dataSaveDraft.status = 0;
            req_dataSaveDraft.maxon_account_no = user_max_acc;

            console.log('Status', req_dataSaveDraft);

            if (IsPartRemoved) {
                allIncPartValues.claim_expiry_date = seSerialNo;
                allIncPartValues.partNum = serPartNum;
                var response_data = await add_claim_api(allIncPartValues);
            } else {
                var response_data = await add_claim_api(req_dataSaveDraft);

            }
            //    return;

            //    console.log('Response Add claim ', response_data);
            // console.log('Before Response ', response_data);
            if (response_data.success) {
                ShowErr(errhidden == false);
                // return
                localStorage.setItem('claim_page_start', 0)
                localStorage.setItem('claim_curr_page', 1)
                if (response_data.new_web_reference_no === '') {
                    SetSuMsg(response_data.msg);
                    // console.log('Normal ', response_data);
                    ShowSucDraft(!suchiddenDraft);
                    setTimeout(() => {
                        ShowSuc(suchiddenDraft = true);
                        SetBtnDisable(btndis = true);
                        return navigate("/claims");
                    }, 1000);
                    reset();
                } else {
                    SetsuWebMSG(true);
                    // console.log('New WEb REF ', response_data);
                    SetNewWEbnoMSG(response_data.new_web_reference_no);
                    setTimeout(() => {
                        SetBtnDisable(btndis = true);
                    }, 2000)
                    setTimeout(() => {
                        // ShowSuc(suchidden = true);
                        reset();
                        setisWarrentyExpired(false);
                        // reset();
                        return navigate("/claims");
                    }, 4000);
                    // reset();
                }
            } else {
                SetErMsg(suMSG = response_data.msg);
                ShowErr(errhidden = true);
                setTimeout(() => {
                    ShowErr(errhidden = false);
                }, 15000);
                SetBtnDisable(btndis = true);
            }
            SetIsAllFieldValid(true);
        }
        SetIsAllFieldValid(true);
    }

    var repRAte = watch(repair_rate);
    useEffect(() => {
        // console.log("hours changed ",current_repairhour_value);
        // SetlaborRate(repair_rate*repRAte);
        setValue(repair_laborTot, repRAte * current_repairhour_value);
        setValue(repairParts_Tot, (Number(repRAte * current_repairhour_value) + Number(current_repairmisc_char_val)))
        // setisHourChangedMsg(true);
        // setValue(repair_laborHour,current_repairhour_value)
        // console.log("aret changed ",repRAte);
        SetlaborCalcTotal(current_repairhour_value * repRAte)
        var getUnitClaim = getValues(Unitprice_claim_val);
        // console.log('unit price claim ',getUnitClaim);
        // SetQuantityunitTotalClaim(getUnitClaim * current_QuantityPrice_val);
        setValue(TotalQU_val, (getUnitClaim * current_QuantityPrice_val));
    }, [current_repairhour_value])


    useEffect(() => {
        // console.log(current_repairrate_val);
        // console.log("000")
        setValue(repair_laborTot, repRAte * current_repairhour_value);
        if (repRAte) {
            SetlaborCalcTotal(repRAte * current_repairhour_value);
        }
        else {
            SetlaborCalcTotal(0)
        }
        setValue(repairParts_Tot, (Number(current_repairmisc_char_val) + Number(repRAte * current_repairhour_value)));
    }, [current_repairrate_val])


    /* Category List Call & Web Ref No */

    useEffect(() => {
        window.scrollTo(0, 0);
        var cateRes = category_list_api().then(res => {
            SetCategoryList(res.data);
        })

        var WebRef = get_web_reference_no_api().then(res => {
            // console.log('category List=',res.data);
            if (res.success) {
                SetWebRefNo(res.web_reference_no);
                setValue('web_reference_number', res.web_reference_no);
            }
        });

        var partlistsRes = parts_listing_api().then(res => {
            if (res.success) {
                console.log('PartLists ', res.data);
                res.data.forEach(element => {
                    // console.log("part desc", element);
                    element.description = element.PartNum + ' - ' + element.PartDescription;
                    const dateObj = new Date();
                    const year = dateObj.getFullYear();
                    setActiveYear(year)
                });
                SetpartLists(res.data);
            }
        })
    }, []);
    var [user_max_acc, Setuser_max_acc] = useState();

    /* Serial NO Call */
    var [seSerialNo, SetseSerialNo] = useState();
    var [serPartNum, setSerPartNum] = useState();
    useEffect(() => {
        Setuser_max_acc(localStorage.getItem('maxonAccount'));
        const request_data = {
            "serial_no": SerialNo_Valid,
        }
        const headers = { "AUTHORIZATION": process.env.REACT_APP_LAMBDA_AUTHORIZATION, 'Content-Type': 'application/json' }

        if (SerialNo_Valid) {
            axios.post(process.env.REACT_APP_VALIDATE_SERIALNO_API, request_data, { headers }).then(response => {
                console.log(response.data)
                if (response.data.status == "Success") {
                    ShowSuc(false)
                    SetSuMsg(response.data.msg)
                    SetErMsg('')
                    ShowErr(false)
                    setTimeout(() => {
                        ShowSuc(true)
                    }, 2000);
                    SetseSerialNo(response.data.data[0].SerialNo_Date02)
                    setSerPartNum(response.data.data[0].SerialNo_PartNum)
                    var enrty_date = getValues('web_entry_date')
                    if (response.data.data[0].SerialNo_Date02 < enrty_date) {
                        setisWarrentyExpired(true);
                    } else {
                        setisWarrentyExpired(false);
                    }
                    SetSerialNoPartDesc(response.data.data[0].Part_PartDescription);
                    setValue("model_no", response.data.data[0].Part_PartDescription);
                    SetSerialNoCompanyName(response.data.data[0].CustomerEndUser_Name);
                    setValue("company_name", response.data.data[0].CustomerEndUser_Name);
                    SetSerialpurchaseDate(moment(response.data.data[0].SerialNo_Date01).format("YYYY-MM-DD"));
                    setValue("purchase_date", moment(response.data.data[0].SerialNo_Date01).format("YYYY-MM-DD"));
                    setisSeiralValidMsg(false);
                }
            }).catch(err => {
                console.log(err);
                ShowErr(true)
                setTimeout(() => {
                    ShowErr(false)
                }, 2000);
                SetErMsg(err.response.data.msg)
                SetSuMsg('')
                SetSerialNoPartDesc('');
                setValue("model_no", '');
                SetSerialNoCompanyName('');
                setValue("company_name", '');
                SetSerialpurchaseDate('');
                setValue("purchase_date", '');
                setisSeiralValidMsg(true);
            });
        }

        // var SerialNosCheck = validate_serial_api(SerialNo_Valid).then(res => {
        //     console.log(res.data)
        //     if (res.success) {
        //         SetseSerialNo(res.data[0].ExpireDate);
        //         setSerPartNum(res.data[0].PartNum)
        //         var enrty_date = getValues('web_entry_date')
        //         if (res.data[0].ExpireDate < enrty_date) {
        //             setisWarrentyExpired(true);
        //         } else {
        //             setisWarrentyExpired(false);
        //         }
        //         SetSerialNoPartDesc(res.data[0].PartDescription);
        //         setValue("model_no", res.data[0].PartDescription);
        //         SetSerialNoCompanyName(res.data[0].Name);
        //         setValue("company_name", res.data[0].Name);
        //         SetSerialpurchaseDate(res.data[0].PurchaseDate);
        //         setValue("purchase_date", res.data[0].PurchaseDate);
        //         setisSeiralValidMsg(false);
        //     } else {
        //         SetSerialNoPartDesc('');
        //         setValue("model_no", '');
        //         SetSerialNoCompanyName('');
        //         setValue("company_name", '');
        //         SetSerialpurchaseDate('');
        //         setValue("purchase_date", '');
        //         setisSeiralValidMsg(true);
        //     }
        // })
    }, [SerialNo_Valid]);

    var Val_known;
    var [Rchecked, Setchecked] = useState('partnochecked');
    useEffect(() => {
        console.log(current_KnownOptionVal)
        if (current_KnownOptionVal == "Part No") {
            Setchecked('partnochecked');
        } else {
            Setchecked('partdescchecked');
        }
        // SetPART_NUMBER('');
        // SetPART_DESC('');
        setTimeout(() => {
            setValue(Parts_No, '');
            setValue(Parts_description_val, '');
            resetField(Parts_No);
            resetField(Parts_description_val);
        }, 1000);
        // if(current_KnownOptionVal == "Part No"){
        //     Setknown_optionVal(true);
        // }else{
        //     Setknown_optionVal(false);
        // }
        // setValue('known_option',current_KnownOptionVal);
        Val_known = current_KnownOptionVal;
        // Setknown_optionVal(Val_known);
        if (Val_known !== undefined && Val_known !== '' && Val_known !== null) {
            Setknown_optionVal(Val_known);
        }
        // setValue('known_option',getValues('known_option'));
        // console.log('Known Option',getValues('known_option'));
        // console.log('Known', Val_known);
    }, [current_KnownOptionVal])

    var [Part_NoList, setPart_NoList] = useState([{}]);
    /* Part Description update based on Part No */
    useEffect(() => {
        // setPart_NoList([]);
        // console.log(current_part_no_val)
        // console.log(known_optionVal);
        if (known_optionVal === "Part No") {
            // setPart_NoList([]);
            if (current_part_no_val != undefined && current_part_no_val.length >= 3) {
                var Part_No_List = parts_list_search_api(current_part_no_val).then(res => {
                    if (res.success) {
                        // console.log('current_part_no_val',current_part_no_val);
                        // SetPART_NUMBER(current_part_no_val);
                        // setValue(Parts_description_val, res.data[0].PartDescription);
                        console.log(res.data);
                        setPart_NoList(res.data)
                        // SetPART_DESC(res.data[0].PartDescription);
                    }
                })
            }
            // else {
            //     setPart_NoList([]);
            // }
            if (current_part_no_val != undefined && current_part_no_val.length < 3) {
                setPart_NoList([{}]);
            }
            var Part_No_Pass = get_part_description_api(current_part_no_val).then(res => {
                if (res.success) {
                    // console.log('current_part_no_val',current_part_no_val);
                    // SetPART_NUMBER(current_part_no_val);
                    setValue(Parts_description_val, res.data[0].PartDescription);
                    SetPART_DESC(res.data[0].PartDescription);
                    setValue(repair_laborTot, repRAte * current_repairhour_value);
                    setValue(repairParts_Tot, (Number(current_repairmisc_char_val) + Number(repRAte * current_repairhour_value)));
                    SetrepairpartstotalCalc((Number(current_repairmisc_char_val) + Number(repRAte * current_repairhour_value)))
                    SetlaborCalcTotal(repRAte * current_repairhour_value);
                    var getUnitClaim = getValues(Unitprice_claim_val);
                    setValue(TotalQU_val, (getUnitClaim * current_QuantityPrice_val));

                    SetQuantityunitTotalClaim((getUnitClaim * current_QuantityPrice_val));
                    // SetQuantityClaim(current_QuantityPrice_val);
                    // setValue(Quantity_claim_val,current_QuantityPrice_val);
                    // SetUnitPriceClaim(current_UnitPrice_val);
                    // setValue(Unitprice_claim_val,current_UnitPrice_val);
                }
            })
        }
    }, [current_part_no_val])

    /* Part Number update based on Part Description */
    useEffect(() => {

        if (known_optionVal === "Part Description") {
            if (current_partdesc_val != undefined && current_partdesc_val != '') {
                var splitpart = current_partdesc_val.split(' - ');
                if (splitpart.length > 1) {
                    splitpart = splitpart[1];
                    setValue(Parts_description_val, splitpart);
                }
            }
            var Part_DESC_Pass = get_part_number_api(splitpart).then(res => {

                if (res.success) {
                    // console.log(current_partdesc_val);
                    setValue(Parts_No, res.data[0].PartNum);
                    // SetPART_DESC(current_partdesc_val);
                    SetPART_NUMBER(res.data[0].PartNum);
                    // console.log('partno update based on description',res.data[0].PartNum);
                    var total_labor = getValues(repair_laborTot);

                    // setValue(repair_laborTot, repRAte * current_repairhour_value);
                    var getUnitClaim = getValues(Unitprice_claim_val);
                    setValue(TotalQU_val, (getUnitClaim * current_QuantityPrice_val));

                    // setValue(laborCalcTotal,current_repairhour_value*repRAte);
                    // SetrepairpartstotalCalc(Number(current_repairmisc_char_val) + Number(total_labor));
                    setValue(repairParts_Tot, (Number(current_repairmisc_char_val) + Number(total_labor)));
                }
            })
        }
    }, [current_partdesc_val])


    useEffect(() => {
        var getUnitClaim = getValues(Unitprice_claim_val);
        // console.log('unit price claim ',getUnitClaim);
        // SetQuantityunitTotalClaim(getUnitClaim * current_QuantityPrice_val);
        setValue(TotalQU_val, (getUnitClaim * current_QuantityPrice_val));
        setValue(repair_laborTot, repRAte * current_repairhour_value);
        setValue(repairParts_Tot, (Number(current_repairmisc_char_val) + Number(repRAte * current_repairhour_value)));
    }, [current_UnitPrice_val, current_QuantityPrice_val])


    /* Gate type fetch based on category ID */
    useEffect(() => {
        var gateRes = gate_type_list_api(current_repaircat_id).then(res => {
            // console.log(res.data);
            if (res.success) {
                SetGateTypeList(res.data);
                SetrepairGatetype(current_repairgate_id);
                // setValue(repair_gate_id, current_repairgate_id);
            }
        });
    }, [current_repaircat_id, current_repairgate_id])


    /* Component type fetch based on gate ID */
    useEffect(() => {
        var componentRes = component_list_api(current_repairgate_id).then(res => {
            if (res.success) {
                // console.log(res.data);
                SetComponent(res.data);
                SetrepairComponent(current_repaircompo_id);
                SetrepairTabChange(false);
                if (current_repaircat_id == '' || current_repaircat_id == undefined || current_repaircat_id == null) {
                    SetrepairComponent('')
                }
                // setValue(repair_component_id, current_repaircompo_id);
            }
        })
    }, [current_repaircompo_id, current_repairgate_id])


    /* Hours fetch 3 Dropdowns */
    useEffect(() => {

        var HoursRes = hours_list_api(current_repaircompo_id).then(res => {
            var hr; var total_labor;
            // if (hr != '') {
            //     IsAllFieldValid.labour_hours = ""
            // }

            if (res.success) {
                if (repairTabChange) {
                    total_labor = current_repairhour_value * current_repairrate_val;
                    SetLaborHours(current_repairhour_value);
                    setValue(repair_laborHour, current_repairhour_value);
                    SetlaborCalcTotal(total_labor);
                    setValue(repair_laborTot, total_labor);
                } else {
                    hr = res.data[0].labour_hours;
                    // console.log("000")
                    // console.log(hr);
                    // console.log(current_repairrate_val);
                    total_labor = hr * current_repairrate_val;
                    // console.log(total_labor);
                    SetLaborHours(hr);
                    if (hr != '') {
                        if (control._formState.errors.repairs) {
                            if (control._formState.errors.repairs[activeRepairIndex].labour_hours && control._formState.errors.repairs[activeRepairIndex].labour_hours.message) {
                                control._formState.errors.repairs[activeRepairIndex].labour_hours.message = '';
                            }
                        }
                    }
                    setValue(repair_laborHour, hr);
                    SetlaborCalcTotal(total_labor);
                    setValue(repair_laborTot, total_labor);
                }
                // getValues();
            }
        })
    }, [current_repaircompo_id])


    /* Misc charge calculation */
    useEffect(() => {
        var total_labor = getValues(repair_laborTot);
        // console.log('labor total', total_labor);
        setValue(repair_laborTot, repRAte * current_repairhour_value);
        var getUnitClaim = getValues(Unitprice_claim_val);
        setValue(TotalQU_val, (getUnitClaim * current_QuantityPrice_val));
        // console.log(current_repairmisc_char_val);
        // setValue(laborCalcTotal,current_repairhour_value*repRAte);
        // SetrepairpartstotalCalc(Number(current_repairmisc_char_val) + Number(total_labor));
        setValue(repairParts_Tot, (Number(current_repairmisc_char_val) + Number(total_labor)));

        if (current_repairmisc_char_val?.length == 0) {
            var Repair_Mdesc_show = "repairs." + activeRepairIndex + ".miscdescshow"
            setValue(Repair_Mdesc_show, false);
        } else {
            var Repair_Mdesc_show = "repairs." + activeRepairIndex + ".miscdescshow"
            setValue(Repair_Mdesc_show, false);
        }

    }, [current_repairmisc_char_val])

    // var purdate = watch("purchase_date")
    // useEffect(() => {
    //     setValue('repair_date', '');
    // }, [purdate])
    // const purchaseDate = moment().subtract(1, purdate);
    // const disableInvoDt = current => {
    //     return current.isAfter(purchaseDate);
    // };


    var title = watch("repair_date");
    useEffect(() => {
        setValue('invoice_date', '');
    }, [title])

    const yesterday = moment().subtract(1, title);
    const disablePastDt = current => {
        return current.isAfter(yesterday);
    };

    var shortDate;
    const getCurrentDateInput = () => {
        const dateObj = new Date();
        // get the month in this format of 04, the same for months
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObj.getDate()).slice(-2);
        const year = dateObj.getFullYear();
        shortDate = `${year}-${month}-${day}`;
        //  console.log(shortDate)
        setValue('web_entry_date', shortDate);
        return shortDate;
    };
    const numValidate = (e) => {
        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength)
        }
    }
    // const handleHour = (e) => {
    //     e.preventDefault()
    //     console.log("wewerwer", e.target.value)
    // }

    function setTwoNumberDecimal(event) {
        this.value = parseFloat(this.value).toFixed(2);
    }
    // function checkValue(event, index) {
    //     SetLaborHours(handleDecimalsOnValue(event.target.value));
    // }

    // function handleDecimalsOnValue(value) {
    //     const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    //     // return value.match(regex)[0];
    // }
    const MinusValueCheck = (event) => {

        // console.log(event);
        exceptThisSymbols.includes(event.key) && event.preventDefault()
        if (event.code === 'Minus') {
            event.preventDefault();
        }

        if (event.target.value === '-') {
            event.preventDefault();
        }
    }

    var NewWEbno;
    const onSubmit = async (req_data, e) => {
        SetIsAllFieldValid(true);
        e.preventDefault();
        var total_labor = getValues(repair_laborTot);
        SetlaborCalcTotal(total_labor)
        // console.log("purchase date", serialNopurchaseDate);
        // console.log("repair date", req_data.repair_date);
        SetBtnDisable(btndis = false);
        // if (req_data.repairs[activeRepairIndex].labour_hours == '') {
        //     // console.log(req_data.repairs[activeRepairIndex].labour_hours)
        //     setabourHour("Labour hour is required")
        //     setTimeout(() => {
        //         setabourHour("")
        //     }, 6000);
        //     SetBtnDisable(btndis = true);
        //     return
        // }
        if (req_data.repair_date < serialNopurchaseDate) {
            // console.log("date check")
            window.scrollTo(0, 0);
            SetBtnDisable(btndis = true);
            setDateValid(true);
            return true;
        } else if (req_data.repair_date > shortDate) {
            window.scrollTo(0, 0);
            SetBtnDisable(btndis = true);
            setCrutdate(true);
            setTimeout(() => {
                setCrutdate(false);
            }, 4000);
            return true;
        }
        else if (req_data.invoice_date > shortDate) {
            window.scrollTo(0, 0);
            SetBtnDisable(btndis = true);
            setRecrutdate(true);
            setTimeout(() => {
                setRecrutdate(false);
            }, 4000);
            return true;
        }
        else if (req_data.repair_date > req_data.invoice_date) {
            window.scrollTo(0, 0);
            SetBtnDisable(btndis = true);
            setInvodate(true);
            setTimeout(() => {
                setInvodate(false);
            }, 4000);
            return true;
        }
        else {
            const curDDate = moment(new Date()).format("MM-DD-YYYY");
            req_dataSaveDraft = getValues();
            // req_dataSaveDraft.web_entry_date = curDDate;
            setDateValid(false);
            setInvodate(false);
            setRecrutdate(false);
            setCrutdate(false);
            req_data.invoice_file = file_invoice;
            req_data.is_upload = true;
            req_data.claim_expiry_date = seSerialNo;
            req_data.partNum = serPartNum;
            req_data.status = 1;
            req_data.maxon_account_no = user_max_acc;


            console.log(req_data);
            // return;
            if (IsPartRemoved) {
                console.log('Removed some parts')
                allIncPartValues.invoice_file = file_invoice;
                allIncPartValues.is_upload = true;
                allIncPartValues.claim_expiry_date = seSerialNo;
                allIncPartValues.partNum = serPartNum;
                const curDDate = moment(new Date()).format("MM-DD-YYYY");
                req_dataSaveDraft = getValues();
                // req_dataSaveDraft.web_entry_date = curDDate;
                var response_data = await add_claim_api(allIncPartValues);
            } else {
                var response_data = await add_claim_api(req_data);
                // console.log('Not removed parts')
            }

            if (response_data.success) {
                ShowErr(errhidden == false);
                localStorage.setItem('claim_page_start', 0)
                localStorage.setItem('claim_curr_page', 1)
                console.log('Response Add claim ', response_data);
                if (response_data.new_web_reference_no === '') {
                    SetSuMsg(suMSG = response_data.msg);
                    ShowSuc(!suchidden);
                    setTimeout(() => {
                        ShowSuc(suchidden = true);
                        SetBtnDisable(btndis = true);
                        return navigate("/claims");
                    }, 1000);
                } else {
                    SetsuWebMSG(true);
                    SetNewWEbnoMSG(response_data.new_web_reference_no);
                    setTimeout(() => {
                        SetBtnDisable(btndis = true);
                    }, 2000)
                    setTimeout(() => {
                        setisWarrentyExpired(false);
                        return navigate("/claims");
                    }, 4000);
                }
            } else {
                SetErMsg(suMSG = response_data.msg);
                ShowErr(errhidden = true);
                setTimeout(() => {
                    ShowErr(errhidden = false);
                }, 15000);
                SetBtnDisable(btndis = true);
            }
        }
    }

    const today = moment();
    const disableFutureDt = current => {
        return current.isBefore(today)
    }

    var [showlistno, setShowListno] = useState(false);
    const DatalistNo = (event) => {
        console.log("part no popup", event.target.value.length);
        if (event.target.value.length > 3) {
            setShowListno(true);
        } else {
            setShowListno(false);
        }
    }

    const DatalistNoOnFocus = () => {
        console.log("onfocus called", showlistno)
        setShowListno(false);
    }


    var comp_reset; var gate_reset; var hr_reset;

    const addRepair = () => {
        // console.log(repair_gate_id);
        // comp_reset = `repairs.${activeRepairIndex + 1}.component`;
        // gate_reset = `repairs.${activeRepairIndex + 1}.gate_type`;
        // hr_reset = `repairs.${activeRepairIndex + 1}.labour_hours`;
        // // SetBtnDisable(btndis=false);
        // setTimeout(() => {
        //     setValue(gate_reset, '', { shouldValidate: false });
        //     resetField(gate_reset);
        //     setValue(comp_reset, '', { shouldValidate: false });
        //     resetField(comp_reset);
        //     setValue(hr_reset, '', { shouldValidate: false });
        //     resetField(hr_reset);

        //     SetrepairComponent('');
        //     SetrepairGatetype('');
        //     SetLaborHours('');

        // }, 1500);

        // setTimeout(() => {
        //     SetBtnDisable(btndis=true);
        // }, 2300);

        var partId = repairs.length + '-0'
        repairs.push({ category: '', gate_type: '', labour_hours: '', labour_rate: '', labour_total: '', misc_charge: '', misc_description: '', repair_description: '', repair_parts_total: '', parts: [{ repairpart: partId, part_number: '', parts_description: '', quantity_claimed: '', unit_price_claimed: '', partstotal: '' }] })

        var len = repairs.length - 1;

        setActiveRepairIndex(len);
        console.log(repairs)
        curRepairPartList = [];

        setcurRepairPartList(...curRepairPartList, repairs[activeRepairIndex].parts);
        setActivePartIndex(0);
        setTimeout(() => {
            activeIndex(len);
        }, 100);

        SetRepairCount(repairCount + 1);
        setValue('repairs_count', repairCount + 1)
        SetPartCount(partCount + 1);
        setValue('parts', partCount + 1)
        SetTotalCount(totalCount + 2);
        setValue('total_claim', totalCount + 2)

        var rep_hour_val = "repairs." + len + ".labour_hours";
        setValue(rep_hour_val, '');
        // console.log(rep_hour_val);
        var rep_gate_val = "repairs." + len + ".gate_type";
        setValue(rep_gate_val, '');
        var rep_comp_val = "repairs." + len + ".component";
        setValue(rep_comp_val, '');
    };


    const addPart = (index) => {


        const repairIndex = repairs.findIndex((_, idx) => idx === index);
        const repairpart = index + "-" + (repairs[repairIndex].parts.length + 1);
        repairs[repairIndex].parts.push({ repairpart: repairpart, part_number: '', parts_description: '', quantity_claimed: 0, unit_price_claimed: 0, partstotal: 0 })

        curRepairPartList = [];

        curRepairPartList = [...curRepairPartList, ...repairs[repairIndex].parts]

        setcurRepairPartList(curRepairPartList);
        setActivePartIndex((curRepairPartList.length - 1))

        if (repairs[index].parts.length < 1) {
            // alert("Part Empty");
            SetpartCheckboxHide(partCheckboxHide = false);
        } else {
            SetpartCheckboxHide(partCheckboxHide = true);
        }

        var part_desc_val = "repairs." + index + ".parts." + (curRepairPartList.length - 1) + ".parts_description";
        setValue(part_desc_val, '');
        var part_no_val = "repairs." + index + ".parts." + (curRepairPartList.length - 1) + ".part_number";
        setValue(part_no_val, '');
        SetPartCount(partCount + 1);
        setValue('parts', partCount + 1)
        SetTotalCount(totalCount + 1);
        setValue('total_claim', totalCount + 1)


    };

    const activeIndex = (index) => {

        setActiveRepairIndex(index);

        // curRepairPartList = [];

        // setTimeout(() => {
        //     curRepairPartList = [...curRepairPartList, ...repairs[index].parts]
        // }, 1000)

        setcurRepairPartList(repairs[index].parts);

    };

    const activeIdx = (index, event) => {
        event.preventDefault();
        setActivePartIndex(index);
    };

    const activeRIndex = (index, event) => {
        // console.log("Active repair");
        event.preventDefault();
        if (index == 0) {
            var repairIdx = index;
        } else {
            var repairIdx = index - 1;
        }
        // unregister(repairs[index]);
        setActiveRepairIndex(index);

        curRepairPartList = [];
        curRepairPartList = [...curRepairPartList, ...repairs[index].parts]
        setcurRepairPartList(curRepairPartList);

        setActivePartIndex(0)
        SetrepairTabChange(true);
        // var getVal = getValues();
        // console.log(getVal);
        // if (getVal.repairs[activeRepairIndex].category == '') {
        //     console.log('asdasd');
        //     var rep_comp_val = "repairs." + index + ".component";
        //     setValue(rep_comp_val, '');
        // }
    };

    const repairRemove = (index, event) => {
        // console.log("Remove repair");
        Setchecked('partnochecked');
        // console.log(current_KnownOptionVal)
        var remove_part_count = repairs[index].parts.length;
        event.preventDefault();
        repairs.splice(index, 1);
        if (index == 0) {
            var repairIdx = index;
        } else {
            var repairIdx = index - 1;
        }
        // unregister(repairs[index]);
        setActiveRepairIndex(repairIdx);
        curRepairPartList = [];

        curRepairPartList = [...curRepairPartList, ...repairs[repairIdx].parts]

        setcurRepairPartList(curRepairPartList);

        SetRepairCount(repairCount - 1);
        setValue('repair_count', repairCount - 1)
        SetPartCount(partCount - remove_part_count);
        setValue('parts', partCount - remove_part_count)
        SetTotalCount(totalCount - remove_part_count - 1);
        setValue('total_claim', totalCount - remove_part_count - 1)
        SetrepairTabChange(true);
    };


    var allIncPartValues;
    var IsPartRemoved;
    var [partCheckboxHide, SetpartCheckboxHide] = useState(true);
    const partRemove = (index, partIndex, event) => {
        IsPartRemoved = localStorage.setItem('ispartremoved', true);
        allIncPartValues = getValues();
        console.log('IS partRemoveD', IsPartRemoved, index, partIndex);
        event.preventDefault();
        allIncPartValues.repairs[index].parts.splice(partIndex, 1);
        console.log('After partRemove', allIncPartValues, index, partIndex);
        // return;
        repairs[index].parts.splice(partIndex, 1);
        console.log(repairs[index].parts);
        if (repairs[index].parts.length < 1) {
            // alert("Part Empty");
            SetpartCheckboxHide(partCheckboxHide = false);
        } else {
            SetpartCheckboxHide(partCheckboxHide = true);
        }

        if (partIndex == 0) {
            var partIndex = partIndex;
        } else {
            var partIndex = partIndex - 1;
        }

        setTimeout(() => {
            activeIndex(index);
            setActivePartIndex(partIndex)
        }, 100);

        var curRepairPartList = [];

        curRepairPartList = [...curRepairPartList, ...repairs[index].parts]

        setcurRepairPartList(curRepairPartList);

        // setValue('repairs', allIncPartValues.repairs);

        curRepairPartList.forEach((element, pidx) => {
            var part_val = "repairs." + index + ".parts." + pidx;
            setValue(part_val, allIncPartValues.repairs[index].parts[pidx]);
        });

        SetPartCount(partCount - 1);
        setValue('parts', partCount - 1)
        SetTotalCount(totalCount - 1);
        setValue('total_claim', totalCount - 1)
    };


    var [showERRfields, SetshowERRfields] = useState(false);
    var [labourHour, setabourHour] = useState();
    const OnError = (error) => {
        console.log('Errors', error);

        console.log(control);
        // console.log(control._formValues)
        var fvalue = control._formValues.repairs;
        fvalue.forEach((val, index) => {
            if (val.gate_type != "" && val.gate_type != null && val.gate_type != undefined && val.gate_type != 'undefined' && val.category != '') {
                if (control._formState.errors.repairs && control._formState.errors.repairs[index] && control._formState.errors.repairs[index].gate_type && control._formState.errors.repairs[index].gate_type.message) {
                    control._formState.errors.repairs[index].gate_type.message = '';
                }
            }
            if (val.component != "" && val.component != null && val.component != undefined && val.component != 'undefined' && val.category != '') {
                if (control._formState.errors.repairs && control._formState.errors.repairs[index] && control._formState.errors.repairs[index].component && control._formState.errors.repairs[index].component.message) {
                    control._formState.errors.repairs[index].component.message = '';
                }
            }
        })
        // if (error.repairs && error.repairs[activeRepairIndex]?.labour_hours?.type == 'required') {
        //     setabourHour("Labour hour is required")
        //     setTimeout(() => {
        //         setabourHour("")
        //     }, 6000);
        //     SetBtnDisable(true)
        //     return
        // }
        SetshowERRfields(true);
        setTimeout(() => {
            SetshowERRfields(false);
        }, 3000);
    }

    const Misc_charge_not_Empty = (event, index) => {

        // console.log(event.target.value);
        // console.log(event.target.value)
        // console.log(event, 'dsd')
        var miscid = "mDesc" + index;
        // var repid = "rDesc" + index
        if (event.target.value.length > 0) {
            document.getElementById(miscid).disabled = false;
        } else {
            document.getElementById(miscid).disabled = true;
            var Repair_Mdesc = "repairs." + activeRepairIndex + ".misc_description"
            // setValue(Repair_Mdesc, '');
        }
        // if (event.target.value.length > 0) {
        //     document.getElementById(repid).disabled = false;
        // } else {
        //     document.getElementById(repid).disabled = true;
        //     var Repair_Rdesc = "repairs." + activeRepairIndex + ".repair_Description"
        //     // setValue(Repair_Rdesc, '');
        // }

        if (event.target.value.length > event.target.maxLength) {
            event.target.value = event.target.value.slice(0, event.target.maxLength)
        }
        if (event.target.value !== '') {
            SetIsMiscFieldValid(true);
        } else {
            SetIsMiscFieldValid(false);
        }
    }
    function cancelpop(event) {
        event.preventDefault();
        localStorage.setItem('fromdetails', 'pagecount')
        setShow(!show)
    }
    function staysamepage() {
        setShow(!show)
    }
    function backtoclaims() {
        return navigate('/claims');
    }
    return (
        <div className="addclaim-container">
            <LEFTMENU />
            {/* <div className="menuall">
                <div className="lmenu">
                    <LEFTMENU />
                    {/* </div>
                <div className="tmenu">
                    <Topmenu />
                </div>
            </div> */}
            <div className="container details_All">
                <div hidden={btndis} className='loader'>
                    <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                </div>
                {isWarrentyExpired && <small className='Warrenty Popup'>
                    Your warranty is expired..
                </small>}
                {showERRfields && <small className='Errmsg Warrenty Popup'>** Please fill the all repair tab fields</small>
                }
                {
                    recrutdate && <small className='Errmsg Warrenty Popup'>*Ensure that the invoice date should not after current date</small>
                }
                {
                    crutdate && <small className='Errmsg Warrenty Popup'>*Ensure that the repair date should not after current date</small>
                }
                {
                    dateValid && <small className='Errmsg Warrenty Popup'>*Ensure that the repair date is after the purchase date</small>
                }
                {
                    invodate && <small className='Errmsg Warrenty Popup'>*Ensure that the invoice date is after the repair date</small>
                }
                <div className="claim_top_bar">
                    <div className='toaster suc draft' hidden={suchiddenDraft}>
                        <h2>Draft added successfully</h2>
                    </div>
                    <div className='toaster suc clmtoster' hidden={suchidden}>
                        <h2>{suMSG}</h2>
                    </div>
                    {errhidden && <div className='toaster fail mdesc' >
                        <h2>{erMSG}</h2>
                    </div>}

                    {suWebMSG && <div className='toaster suc pop'>
                        <h2>Claim added successfully. {`Your Web ref no is`} <span className='RefNo'>{NewWEbnoMSG}</span></h2>
                    </div>}
                    <div className=" user_topbar">
                        <div className="">
                            <h3>Add New Claim</h3>
                        </div>
                        <div className="warrtylog" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            <div className="logImg"><img src={newlogout}></img></div>
                            <div className="warrntyp" >
                                <p>LOG OUT</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="previous_icon row">
                    <div className="col-md-2" onClick={cancelpop}>
                        <img src={backnew} />
                        <span> Back to Claims</span>
                    </div>
                </div>
                {/* <div className="add_claim_title">
                    <p>Add New Claim</p>
                </div> */}
                <div>
                    {
                        show && <div className="CanPop">
                            <div className="can_popTotal">
                                <div className='can_title'>
                                    Confirmation
                                </div>
                                <hr />
                                <div className='cancel_content'>
                                    <p>
                                        By cancelling, any data you have provided will not be saved.
                                    </p>
                                </div>
                                <hr />
                                <div className='cancel_btns'>
                                    <button className='can_no' onClick={staysamepage}>Cancel</button>
                                    <button className='can_yes' onClick={backtoclaims}>Ok</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="card container add_card_total" >
                    <div className="card-body">

                        <form className="multiform">
                            <div className="basic-info-fields">
                                <div className="subName">
                                    <p>Basic Information</p>
                                </div>
                                <div className="row">
                                    {/* <div className="col-md-6">
                                    <label>Web Reference Number</label>
                                    <input readOnly type="text" className="form-control"
                                        onKeyDown={MinusValueCheck} placeholder="Enter the Web Ref No." value={WebRefNo}
                                        {...register(`web_reference_number`, {
                                            required: {
                                                value: IsAllFieldValid,
                                                message: 'Web Ref No is required'
                                            }
                                        })} />
                                    <small className='errMsg'> {errors.web_reference_number?.message}</small>
                                </div> */}
                                    <div className="col-md-3 lable_cs webdate" >
                                        <label>Web Entry Date*</label>
                                        <input type="date" readOnly disabled className="form-control" defaultValue={getCurrentDateInput()} placeholder="Enter the Web Entry Date" name='web_entry_date' {...register("web_entry_date", {
                                            required: true,
                                        })} />
                                        {errors.web_entry_date?.type === "required" && (
                                            <small className='errMsg'>Web Entry Date is required</small>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 lable_cs">
                                        <label>Serial Number*</label>
                                        <input type="text" className="form-control"
                                            id="serialnothis"
                                            onKeyDown={MinusValueCheck} placeholder="Enter the Serial Number" name='serial_no' {...register("serial_no", {
                                                required: IsAllFieldValid,
                                            })} />
                                        {isSeValidMsg ? <small className="errMsg">Please enter valid Serial No<br /></small> : ''}

                                        {IsAllFieldValid && errors.serial_no?.type === "required" && (
                                            <small className='errMsg'>Serial No is required</small>
                                        )}
                                    </div>

                                    <div className="col-md-3 lable_cs">
                                        <label>Repair Date*</label>
                                        {/* <img src={dateicon} /> */}
                                        <input type="date" id="date" className="form-control" value={title}
                                            max={moment().format("YYYY-MM-DD")} placeholder="Enter the Repair Date" name='repair_date' {...register("repair_date", {
                                                required: IsAllFieldValid,
                                            })} />
                                        {IsAllFieldValid && errors.repair_date?.type === "required" && (
                                            <small className='errMsg'>Repair Date is required</small>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 lable_cs">
                                        <label>Invoice No.*</label>
                                        <input type="text" className="form-control" placeholder="Enter the Invoice No." maxLength={30} onInput={numValidate} name='invoice_no' {...register("invoice_no", {
                                            required: IsAllFieldValid,
                                            maxLength: 30
                                        })} />
                                        {IsAllFieldValid && errors.invoice_no?.type === "required" && (
                                            <small className='errMsg'>Invoice No is required</small>
                                        )}
                                        {IsAllFieldValid && errors.invoice_no?.type === "maxLength" && (
                                            <small className='errMsg'>Max length exceeded</small>
                                        )}
                                    </div>

                                    <div className="col-md-6 lable_cs">
                                        <label>Cycle Count</label>
                                        <input type="number" onWheel={(e) => e.target.blur()}
                                            maxLength={10} className="form-control" onInput={numValidate} onKeyDown={MinusValueCheck} min="0" placeholder="Enter the Cycle Count"  {...register("cycle_count")} />
                                        {errors.cycle_count?.type === "required" && (
                                            <small className='errMsg'>Cycle Count is required</small>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 lable_cs">
                                        <label>Invoice Date*</label>
                                        <input type="date" className="form-control" placeholder="Enter the Invoice Date" name='invoice_date' max={moment().format("YYYY-MM-DD")} min={title} {...register("invoice_date", {
                                            required: IsAllFieldValid,
                                        })} />
                                        {IsAllFieldValid && errors.invoice_date?.type === "required" && (
                                            <small className='errMsg'>Invoice Date is required</small>
                                        )}
                                    </div>
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6 lable_cs">
                                        <label>Part Description</label>
                                        <input readOnly type="text" className="form-control" placeholder="" value={serialNopartdesc}
                                            {...register("model_no", {
                                                required: IsAllFieldValid,
                                            })}
                                        />
                                        {/* {IsAllFieldValid && errors.model_no?.type === "required" && (
                                            <small className='errMsg'>Model No is required</small>
                                        )} */}
                                    </div>
                                </div>
                                <hr />
                                <div className="subName">
                                    <p>End User Information</p>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 lable_cs">
                                        <label>Company Name</label>
                                        <input type="text" className="form-control" readOnly
                                            value={serialNocompanyname}
                                            placeholder="" name='company_name' {...register("company_name")} />
                                        {/* {IsAllFieldValid && errors.company_name?.type === "required" && (
                                            <small className='errMsg'>Company Name is required</small>
                                        )} */}
                                    </div>
                                    <div className="col-md-3 lable_cs purchase_date">
                                        <label>Purchase Date</label>
                                        <input type="date" readOnly disabled className="form-control"

                                            placeholder="Enter the Purchase Date" name='purchase_date' {...register("purchase_date", {
                                                required: IsAllFieldValid,
                                            })} />
                                        {/* {IsAllFieldValid && errors.purchase_date?.type === "required" && (
                                            <small className='errMsg'>Purchase Date is required</small>
                                        )} */}
                                    </div>
                                </div>

                                <hr />
                                <div className="subName">
                                    <p>Summary</p>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 lable_cs">
                                        <label>Parts</label>
                                        <input readOnly type="text" className="form-control" placeholder="Enter the Parts" value={partCount} {...register("parts", {
                                            required: false,
                                        })} />
                                        {errors.parts?.type === "required" && (
                                            <small className='errMsg'>Parts is required</small>
                                        )}
                                    </div>
                                    <div className="col-md-6 lable_cs">
                                        <label>Repairs</label>
                                        <input readOnly type="text" className="form-control" placeholder="Enter the Repairs" value={repairCount}  {...register("repairs_count", {
                                            required: false,
                                        })} />
                                        {errors.repairs?.type === "required" && (
                                            <small className='errMsg'>Repairs is required</small>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 lable_cs">
                                        <label>Total Claim</label>
                                        <input readOnly type="text" className="form-control" placeholder="Enter the Total Claim" name='total_claim'
                                            value={totalCount}
                                            {...register("total_claim", {
                                                required: false,
                                            })} />
                                        {errors.total_claim?.type === "required" && (
                                            <small className='errMsg'>Total Claim is required</small>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label>Upload Invoice</label>
                                        <input type="file" className="form-control"
                                            accept=".jpg,.jpeg,.png,.pdf"
                                            {...register("invoice_file",
                                            )} />
                                        <p className='file_format'>*Formats Accepted jpg,jpeg,png,pdf. Max file size 15MB</p>
                                        {/* {errors.invoice_file?.type === "required" && (
                                            <small className='errMsg'>Invoice file is required</small>
                                        )} */}
                                        {fileerr && <small className='errMsg'>*Invalid file format</small>}
                                        {fileerrsize && <small className='errMsg'>*File size exceeds the allowed limit of 15MB</small>}

                                    </div>
                                </div>
                            </div>

                            <div className="Repairs_fields">
                                <div className="container">
                                    {/* <div> */}
                                    <h4>Add New Repair</h4>
                                    <div className="repair-tabs">
                                        {repairs.map((repair, index) => (
                                            <React.Fragment key={index + 'sS'}>
                                                <div className="repair-intabs tabsspace">
                                                    <button
                                                        className={index === activeRepairIndex ? 'active' : ''}
                                                        onClick={(event) => activeRIndex(index, event)}
                                                    >
                                                        Repair {index + 1}
                                                    </button>
                                                    {repairs.length > 1 && (
                                                        <button key={index + 'a'}
                                                            className='repair-tabss close active' onClick={(event) => { repairRemove(index, event); removeRepair(index) }}>
                                                            X
                                                        </button>)}
                                                </div>
                                            </React.Fragment>
                                        ))}

                                        <button type="button" className='addrepairbtn' onClick={addRepair}>
                                            +
                                        </button>
                                    </div>

                                    <div className="repair-details">
                                        {repairs.map((repair, index) => (
                                            <div key={index + 'g'} className={index === activeRepairIndex ? 'active' : 'inactive'}>
                                                {(
                                                    <>
                                                        {/* <hr /> */}
                                                        <div className="row">
                                                            <div className="col-md-6 lable_cs">
                                                                <label>Category*</label>
                                                                <div className="custom-select">
                                                                    <select className="form-select" aria-label="Default select example" {...register(`repairs.${index}.category`, {
                                                                        required:
                                                                            { value: IsAllFieldValid, message: 'Category is required' }
                                                                    })}>
                                                                        <option value=''>Select</option>
                                                                        {category_list.map((catelist) => {
                                                                            return (
                                                                                <option value={catelist.id} key={catelist.id}>{catelist.category}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                    {IsAllFieldValid && errors?.repairs?.[index]?.category && <small className='errMsg'>{errors?.repairs?.[index]?.category.message}</small>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 lable_cs">
                                                                <label>Gate Type*</label>
                                                                <div className="custom-select">
                                                                    <select value={repairGatetype} className="form-select" aria-label="Default select example" {...register(`repairs.${index}.gate_type`, {
                                                                        required:
                                                                        {
                                                                            value: IsAllFieldValid, message: 'Gate type is required'
                                                                        }
                                                                    })}>
                                                                        <option value=''>Select</option>
                                                                        {gate_type_list && gate_type_list.map((gatelist) => {
                                                                            return (
                                                                                <option key={gatelist.id} value={gatelist.id}>{gatelist.gate_type}</option>
                                                                            )
                                                                        })}

                                                                    </select>
                                                                    {IsAllFieldValid && errors?.repairs?.[index]?.gate_type && <small className='errMsg'>{errors?.repairs?.[index]?.gate_type.message}</small>}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 lable_cs">
                                                                <label>Component*</label>
                                                                <div className="custom-select">
                                                                    <select value={repairComponent} className="form-select" aria-label="Default select example" {...register(`repairs.${index}.component`, {
                                                                        required:
                                                                        {
                                                                            value: IsAllFieldValid, message: 'Component is required'
                                                                        }
                                                                    })}>
                                                                        <option value=''>Select</option>
                                                                        {component && component.map((componentlist) => {
                                                                            return (
                                                                                <option key={componentlist.id} value={componentlist.id}>{componentlist.component}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                    {IsAllFieldValid && errors?.repairs?.[index]?.component && <small className='errMsg'>{errors?.repairs?.[index]?.component.message}</small>}
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="subName">
                                                                <p>Labor</p>
                                                            </div>
                                                            <div className="col-md-6 lable_cs">
                                                                <label>Hours*</label>
                                                                <input type="number" inputmode="numeric" min="1" onWheel={(e) => e.target.blur()}
                                                                    className="form-control"
                                                                    defaultValue={laborhours} maxLength={10} onInput={numValidate}
                                                                    inputMode="decimal"
                                                                    onKeyDown={MinusValueCheck}
                                                                    placeholder="Enter the Hours" {...register(`repairs.${index}.labour_hours`, {
                                                                        required:
                                                                        {
                                                                            value: IsAllFieldValid, message: 'Hours is required'
                                                                        }
                                                                    })} />

                                                                {/* <small className='errMsg'> {labourHour}</small> */}
                                                                {/* {isHourChangedMsg && <small className='errMsg'>You are changing actual hours</small>} */}
                                                                {IsAllFieldValid && errors?.repairs?.[index]?.labour_hours && <small className='errMsg'>{errors?.repairs?.[index]?.labour_hours.message}</small>}

                                                            </div>
                                                            <div className="col-md-6 lable_cs">
                                                                <label>Rate*</label>
                                                                <div className="dollarsign">
                                                                    <input type="number" maxLength={10} onInput={numValidate} onWheel={(e) => e.target.blur()}
                                                                        inputMode="decimal"
                                                                        className="form-control"

                                                                        onKeyDown={MinusValueCheck} min='1' placeholder="Enter the Rate" {...register(`repairs.${index}.labour_rate`, {
                                                                            required:
                                                                                { value: IsAllFieldValid, message: 'Labor Rate is required' }, min: { value: 1, message: 'Please add greater than 0' }
                                                                        })} />
                                                                    {IsAllFieldValid && errors?.repairs?.[index]?.labour_rate && <small className='errMsg'>{errors?.repairs?.[index]?.labour_rate.message}</small>}
                                                                </div>

                                                            </div>
                                                            <div className="col-md-6 lable_cs">
                                                                <label>Repair Description*</label>
                                                                <textarea type="text" className="form-control" placeholder="Enter the Repair Description" {...register(`repairs.${index}.repair_Description`, {
                                                                    required:
                                                                        { value: IsAllFieldValid, message: 'Repair Description is required' }
                                                                })} ></textarea>
                                                                {errors?.repairs?.[index]?.repair_Description && <small className='errMsg'>{errors?.repairs?.[index]?.repair_Description.message}</small>}
                                                            </div>
                                                            <div className="col-md-6 lable_cs">
                                                                <label>Total</label>
                                                                <div className="dollarsign">
                                                                    <input type="number" min='0'
                                                                        readOnly
                                                                        value={laborCalcTotal}
                                                                        onKeyDown={MinusValueCheck} className="form-control" placeholder="Enter the Total" {...register(`repairs.${index}.labour_total`)} />
                                                                    {errors?.repairs?.[index]?.labour_total && <small className='errMsg'>{errors?.repairs?.[index]?.labour_total.message}</small>}
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="col-md-6 lable_cs">
                                                                <label>Misc charge</label>
                                                                <div className="dollarsign">
                                                                    <input type="number" maxLength={10} min='0' onWheel={(e) => e.target.blur()} onInput={(event) => Misc_charge_not_Empty(event, index)}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9 .]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }} className="form-control" onKeyDown={MinusValueCheck} placeholder="Enter the Misc Charge" {...register(`repairs.${index}.misc_charge`, {
                                                                            required:
                                                                                { value: false, message: 'Misc charge is required' }, min: { value: 1, message: 'Please add greater than 0' }
                                                                        })} />
                                                                    {errors?.repairs?.[index]?.misc_charge && <small className='errMsg'>{errors?.repairs?.[index]?.misc_charge.message}</small>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 lable_cs"></div>
                                                            <div className="col-md-6 lable_cs">
                                                                <label>Misc Description</label>
                                                                <textarea type="text" id={'mDesc' + index} disabled="disabled" className="form-control" placeholder="Enter the Misc Description" {...register(`repairs.${index}.misc_description`)} ></textarea>
                                                                {errors?.repairs?.[index]?.misc_description && <small className='errMsg'>{errors?.repairs?.[index]?.misc_description.message}</small>}
                                                            </div>



                                                            <div className="col-md-6 lable_cs">
                                                                <label>Repair Total</label>
                                                                <div className="dollarsign">
                                                                    <input type="number" defaultValue={repairpartstotalCalc} className="form-control" readOnly  {...register(`repairs.${index}.repair_parts_total`, {
                                                                        required:
                                                                            { value: false, message: 'Repair Parts Total is required' }
                                                                    })}
                                                                    />
                                                                    {errors?.repairs?.[index]?.repair_parts_total && <small className='errMsg'>{errors?.repairs?.[index]?.repair_parts_total.message}</small>}
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <h4>Add New Part</h4>
                                                            <div className="part-tabs-border">
                                                                <div className="part-tabs clmparts">
                                                                    {curRepairPartList && curRepairPartList.map((part, partIndex) => (
                                                                        <button
                                                                            key={index + ' ' + partIndex}
                                                                            className={partIndex === activePartIndex ? 'active' : ''} aria-selected="true"
                                                                            onClick={(event) => activeIdx(partIndex, event)}
                                                                        >
                                                                            Part {partIndex + 1}
                                                                            <span className='close' onClick={(event) => { partRemove(index, partIndex, event); }}>X</span>
                                                                        </button>
                                                                    ))}
                                                                    <button type="button" className='addpartbtn' onClick={() => addPart(index)}>
                                                                        +
                                                                    </button>
                                                                </div>
                                                                <div className="part-details">
                                                                    {(partCheckboxHide && <div className="row">
                                                                        <div className="col-md-6 lable_cs">
                                                                            <div className="known-opt" >
                                                                                <label htmlFor="">Please select which one you know.</label>
                                                                                <div className="known-optin-top">
                                                                                    <div className="known-optin partno" data-val={Rchecked}>
                                                                                        <label htmlFor="Partno">
                                                                                            <input type="radio" id="Partno" {...register('known_option')} value="Part No" data-val={Rchecked} />
                                                                                            Part Number</label>
                                                                                    </div>
                                                                                    <div className="known-optin partdesc" data-val={Rchecked}>
                                                                                        <label htmlFor="PartDes">
                                                                                            <input type="radio" id="PartDes" {...register('known_option')} value="Part Description" data-val={Rchecked} />
                                                                                            Part Description</label><br />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>)}
                                                                    {curRepairPartList && curRepairPartList.map((part, partIndex) => (
                                                                        <div key={partIndex + 'c'} className={partIndex === activePartIndex ? 'active' : 'inactive'}>
                                                                            {(
                                                                                <>

                                                                                    {known_optionVal === "Part No" && <div className="row">
                                                                                        <div className="col-md-6 lable_cs">
                                                                                            <label>Part Number</label>

                                                                                            <input list="partlists" autoComplete="off" name="partlist" id="partlist" className="form-control" placeholder="Enter the Part Number " {...register(`repairs.${index}.parts.${partIndex}.part_number`)} />

                                                                                            <datalist id="partlists">
                                                                                                {Part_NoList && Part_NoList.map((part) => (
                                                                                                    <option key={part.id} value={part.PartNum} />
                                                                                                ))}
                                                                                            </datalist>


                                                                                            {/* <input type="text" className="form-control" placeholder="Enter the Part Number " {...register(`repairs.${index}.parts.${partIndex}.part_number`)} /> */}
                                                                                            {errors?.repairs?.[index]?.[partIndex]?.part_number && <small className='errMsg'>{errors?.repair?.[index]?.[partIndex]?.part_number.message}</small>}
                                                                                        </div>
                                                                                        <div className="col-md-6 lable_cs">
                                                                                            <label>Part Description</label>
                                                                                            {/* <input list="partDescs" value={PART_DESC} name="partDesc" id="partDesc" className="form-control" placeholder="Enter the Part Description " {...register(`repairs.${index}.parts.${partIndex}.parts_description`)} />
                                                                                            <datalist id="partDescs">
                                                                                                {partLists.map((part) => (
                                                                                                    <option key={part.id} value={part.PartDescription} />
                                                                                                ))}
                                                                                            </datalist> */}
                                                                                            <input readOnly={known_optionVal === "Part No"} type="text" defaultValue={PART_DESC} className="form-control" placeholder="Enter the Part Description " {...register(`repairs.${index}.parts.${partIndex}.parts_description`)} />
                                                                                            {errors?.repairs?.[index]?.[partIndex]?.parts_description && <small className='errMsg'>{errors?.repair?.[index]?.[partIndex]?.parts_description.message}</small>}
                                                                                        </div>
                                                                                    </div>}

                                                                                    {known_optionVal === "Part Description" && <div className="row">
                                                                                        <div className="col-md-6 lable_cs">
                                                                                            <div className="partnoarea">
                                                                                                <label>Part Number</label>
                                                                                                {/* <input list="partlists" name="partlist" id="partlist" className="form-control" placeholder="Enter the Part Number " {...register(`repairs.${index}.parts.${partIndex}.part_number`)} /> */}
                                                                                                {/* <datalist id="partlists" >
                                                                                                {partLists.map((part) => (
                                                                                                    <option key={part.id} value={part.PartNum} />
                                                                                                ))}


                                                                                            </datalist> */}
                                                                                                {/* <span class='partno-append'>{PART_NUMBER}</span> */}
                                                                                                <input type="text" readOnly={known_optionVal === "Part Description"} value={PART_NUMBER} className="form-control" placeholder="Enter the Part Number " {...register(`repairs.${index}.parts.${partIndex}.part_number`)} />
                                                                                                {errors?.repairs?.[index]?.[partIndex]?.part_number && <small className='errMsg'>{errors?.repair?.[index]?.[partIndex]?.part_number.message}</small>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6 lable_cs">
                                                                                            <label>Part Description</label>
                                                                                            <input type='text' list="partDescs" name="partDesc" id="partDesc" className="form-control" placeholder="Enter the Part Description " {...register(`repairs.${index}.parts.${partIndex}.parts_description`)} />
                                                                                            <datalist id="partDescs">
                                                                                                {partLists.map((part) => (
                                                                                                    <option key={part.id} value={part.description} />
                                                                                                ))}


                                                                                            </datalist>
                                                                                            {/* <input type="text" value={PART_DESC} className="form-control" placeholder="Enter the Part Description " {...register(`repairs.${index}.parts.${partIndex}.parts_description`)} /> */}
                                                                                            {errors?.repairs?.[index]?.[partIndex]?.parts_description && <small className='errMsg'>{errors?.repair?.[index]?.[partIndex]?.parts_description.message}</small>}
                                                                                        </div>
                                                                                    </div>}
                                                                                    <div className="row">
                                                                                        <div className="col-md-6 lable_cs">
                                                                                            <label>Quantity Claimed</label>
                                                                                            <input type="number" onWheel={(e) => e.target.blur()} onInput={numValidate} maxLength={10} onKeyDown={MinusValueCheck} min='0' className="form-control" placeholder="Enter the Quantity Claimed" {...register(`repairs.${index}.parts.${partIndex}.quantity_claimed`)} />
                                                                                            {errors?.repair?.[index]?.[partIndex]?.quantity_claimed && <small className='errMsg'>{errors?.repair?.[index]?.[partIndex]?.quantity_claimed.message}</small>}
                                                                                        </div>
                                                                                        <div className="col-md-6 lable_cs">
                                                                                            <label>Unit Price Claimed</label>
                                                                                            <div className="dollarsign">
                                                                                                <input type="number" inputmode="numeric" maxLength={10}
                                                                                                    onWheel={(e) => e.target.blur()}
                                                                                                    onInput={numValidate} onKeyDown={MinusValueCheck} min='1' className="form-control" placeholder="Enter the Unit Price Claimed " {...register(`repairs.${index}.parts.${partIndex}.unit_price_claimed`)} />

                                                                                                {errors?.repair?.[index]?.[partIndex]?.unit_price_claimed && <small className='errMsg'>{errors?.repair?.[index]?.[partIndex]?.unit_price_claimed.message}</small>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6 lable_cs">
                                                                                            <label>Total</label>
                                                                                            <div className="dollarsign">
                                                                                                <input type="number" readOnly defaultValue={QuantityunitTotalClaim} className="form-control" placeholder="Enter the Total" {...register(`repairs.${index}.parts.${partIndex}.partstotal`)} />
                                                                                                {errors?.repair?.[index]?.[partIndex]?.partstotal && <small className='errMsg'>{errors?.repair?.[index]?.[partIndex]?.partstotal.message}</small>}
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    <br />
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <hr />
                                <div className="btn-group addclaim">
                                    {/* <Link to={'/claims'}> */}
                                    <button className="cancal-sub-btn" onClick={cancelpop}>
                                        <img src={CanCelBtn} alt='cancel-icon' /> Cancel </button>
                                    {/* </Link> */}
                                    <button className="savedraft-btn" onClick={handleSubmitDraft}>
                                        <img src={DraftBTNIcon} alt='draft-icon' /> Save Draft </button>
                                    <button type="submit" className="addclaim-sub-btn" onClick={handleSubmit(onSubmit, OnError)}>
                                        <img src={SubmitBTNIcon} alt='submit-icon' /> Submit </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
                <div className="home_footer claim_footer">
                    <div className="footer_copy">
                        <p>Copyright &copy; {activeyear} Maxon Lift Crop. All rights reserved</p>
                    </div>
                    <div className="home_content">
                        <div className="clmuser" onClick={onResumeClick} >
                            <a target="_blank"><span>User Tutorial</span></a>
                        </div>
                        <Link to={'/privacy-policy'}>
                            Policy Privacy</Link>
                        <Link to={'/terms-Conditions'}>
                            Terms & Conditions</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClaimForm;