import React, { useState } from "react";
import UserIcon from '../assets/user_icon.png';
import { useForm } from 'react-hook-form';
import forget_password_api from '../api/forget_password_api.js';
import { useNavigate } from 'react-router-dom';
import "../pages/forgot.css";
import GIFLOADER from '../assets/loader.gif';
import { useEffect } from 'react';
import maxon_logo from '../assets/max_logo.png';
import Menu from "./Menu";
import Footer from "./Footer.js";
import NewUserIcon from '../assets/question.png';
import pdf from "../assets/maxontutorial.pdf";



function Forgetpassword() {

    var [suchidden, ShowSuc] = useState(true);
    var [errhidden, ShowErr] = useState(true);

    var [suMSG, SetSuMsg] = useState('');
    var [erMSG, SetErMsg] = useState('');

    var [enableloader, SetBtnDisable] = useState(true);


    const navigate = useNavigate();

    const {
        register, reset,
        handleSubmit,
        formState: { errors }, setValue
    } = useForm();

    useEffect(() => {
        // window.history.forward();
    });
    function onResumeClick() {
        window.open(pdf);
    }
    function BackToLogin() {
        return navigate("/")
    }
    const ForgotSubmit = async (req_data, event) => {
        SetBtnDisable(enableloader = false);
        var response_data = await forget_password_api(req_data.email_id,'1');
        console.log(response_data);

        if (response_data.success) {
            SetSuMsg(suMSG = response_data.msg);
            ShowSuc(!suchidden);
            // console.log(setValue('email_id', ''))
            setTimeout(() => {
                ShowSuc(suchidden = true);
                SetBtnDisable(enableloader = true);
                return navigate("/");
            }, 3000);

        } else {
            SetErMsg(erMSG = response_data.msg);
            ShowErr(!errhidden);
            setTimeout(() => {
                ShowErr(errhidden = true);
            }, 3000);
            SetBtnDisable(enableloader = true);
        }

    }
    return (
        <div className="container-bg pt-5">
            <div className="container">
                <div hidden={enableloader} className='loader'>
                    <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                </div>
                <div className='toster suc' hidden={suchidden}>
                    <h2>{suMSG}</h2>
                </div>
                <div className='header d-flex justify-content-between d-none d-md-flex'>
                    <img className='logo' src={maxon_logo} alt="Maxon logo" />
                    <Menu />
                </div>
                {/* <div className="modal-header">
                <h5 className="text-center modal-title forget_title" id="exampleModalLabel">Forgot Password</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div> */}
                {/* <div className='header d-flex justify-content-center d-none d-md-flex forget_icon'>
                    <img src={maxon_logo} alt="Maxon logo" />
                    <Menu />
                </div> */}
                <div className="max_mob">
                    <img className='logo' src={maxon_logo} alt="Maxon logo" />
                </div>
                <div className="login_box forget_box">
                    <div className="forget_head">
                        <h3>Forget Password </h3>
                    </div>
                    <div className="password_icons forget_smal">
                        <div className="pass-wrapper">
                            <img src={UserIcon} alt="User_icon" />
                            <input placeholder="Email" id="email_id"
                                {...register("email_id", {
                                    required: "Please enter an email address",
                                    validate: {
                                        maxLength: (v) =>
                                            v.length <= 50 || "The email should have at most 50 characters",
                                        matchPattern: (v) =>
                                            /^[a-zA-Z0-9]+([._-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,3}$/.test(v) ||
                                            "Please enter a valid email address ",
                                    },
                                })} />
                        </div>

                        {errors.email_id?.message && (
                            <small className='errMsg'>{errors.email_id.message}</small>
                        )}<br />
                        <div className='toster_Msg fail' hidden={errhidden}>
                            <span>{erMSG}</span>
                        </div>
                    </div>

                    <div className="forget_btns">
                        <button className="forget_can" onClick={BackToLogin}>Cancel</button>
                        <button type="button" className="forget_submit" onClick={handleSubmit(ForgotSubmit)}> Submit</button>
                    </div>
                    <div  className='pt-4 d-sm-block d-md-none forNew' onClick={onResumeClick} >
                        <img src={NewUserIcon} alt="New User Icon" />
                        <a target="_blank"><span>USER TUTORIAL</span></a>
                    </div>
                </div>
                <div className="forget_foot">
                    <Footer />
                </div>
            </div>
        </div>
    )
}



export default Forgetpassword